import React, { useEffect, useState } from 'react';
import TransitionLeft from '@fingo/lib/components/animations/TransitionLeft';
import { useGetBackOfficeUser, useIsMobile } from '@fingo/lib/hooks';
import useLocalStorageTimer from '@fingo/lib/hooks/useLocalStorageTimer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Stack from '@mui/material/Stack';
import { keyframes } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import NewReleases from '@mui/icons-material/NewReleases';
import { useHistory } from 'react-router-dom';
import dayjs from '@fingo/lib/config/dayjs';
import { getSerializedSearch } from '@fingo/lib/helpers';

const rotation = 1;

const myKeyframe = keyframes`
    0 %  { transform: translate(1px, 1px)   rotate(0deg)    },
    50%  { transform: translate(-1px, 1px)  rotate(-${rotation}deg);  },
`;

/*  This Snackbar may be refactor when push notifications are implemented */

const PendingRiskRestrictionsSnackBar = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const {
    lastTimeStored: lastRiskRestrictionAlertShowed,
    resetTimer: resetRiskRestrictionShowed,
  } = useLocalStorageTimer('PENDING_RISK_RESTRICTIONS');

  const currentUser = useGetBackOfficeUser();
  const history = useHistory();

  const invoicesInOperationWithRestriction = currentUser?.invoicesInOperationWithRestrictionsCount;

  useEffect(() => {
    const checkConditions = () => {
      if (
        dayjs().diff(lastRiskRestrictionAlertShowed, 'minutes') >= 30
        && currentUser
        && invoicesInOperationWithRestriction
      ) {
        setShowSnackbar(true);
      }
    };

    const timerId = setInterval(() => {
      checkConditions();
    }, 60000 * 5); // Check every 5 minutes

    return () => clearInterval(timerId);
  }, [
    currentUser,
    lastRiskRestrictionAlertShowed,
    invoicesInOperationWithRestriction,
  ]);

  const handleClick = () => {
    history.push(
      `/app/comercial/invoices-management/operations-manager?${getSerializedSearch(
        { filterByPendingRestrictions: true },
      )}`,
    );
    setShowSnackbar(false);
    resetRiskRestrictionShowed();
  };

  const handleClose = () => {
    setShowSnackbar(false);
    resetRiskRestrictionShowed();
  };

  const isMobile = useIsMobile();
  if (isMobile) return null;

  const alertMessage = `Tienes ${invoicesInOperationWithRestriction} 
  ${
  invoicesInOperationWithRestriction === 1 ? 'factura' : 'facturas'
} con restrcciones por resolver!`;

  return (
    <Snackbar
      open={showSnackbar}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        animation: `${myKeyframe} 1s infinite ease`,
        mt: 4,
      }}
    >
      <SnackbarContent
        sx={{ backgroundColor: 'primary.main' }}
        message={(
          <Stack direction="row" alignItems="center">
            <NewReleases sx={{ color: 'white' }} />
            <Button
              size="small"
              onClick={handleClick}
              variant="link"
              sx={{ color: 'white' }}
            >
              {alertMessage}
            </Button>
            <IconButton sx={{ color: 'white' }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        )}
      />
    </Snackbar>
  );
};

export default PendingRiskRestrictionsSnackBar;
